import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SectionLayout from '../components/section-layout'

const ConservationPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: { frontmatter: { tags: { eq: "conservation" } } }) {
        edges {
          node {
            frontmatter {
              title
              slug
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    src
                  }
                }
              }
            }
          }
        }
      }
      hero: file(relativePath: { eq: "conservation-page.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  return <SectionLayout pageTitle="Conservation" data={data} />
}

export default ConservationPage
